
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Subscription"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Subscription"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"startDate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"endDate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isStripeSubscription"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"billingFrequency"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"features"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"value"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"valueType"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"isTrial"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"plan"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":227}};
    doc.loc.source = {"body":"fragment Subscription on Subscription {\n  status\n  startDate\n  endDate\n  isStripeSubscription\n  billingFrequency\n  features {\n    name\n    description\n    value\n    valueType\n  }\n  isTrial\n    plan {\n      id\n      name\n    }\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    

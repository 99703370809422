<template lang="pug">
  .subscriptions-company(v-if="company")
    .grid.grid-cols-3.gap-4.mt
      .col-span-1
        app-panel
          app-header Details
          .p-4.border-b-1.border-grey-30(v-for="field in detailsPanel")
            .grid.grid-cols-4
              .col-span-2
                app-header(size="h3") {{ field.label }}
              .col-span-1 {{ field.value }}
              .col-span-1
                app-dropdown(:options="field.options", @click="editActionClicked", v-if="field.options")
                  template(#trigger)
                    app-icon.cursor-pointer(icon="edit")
      .col-span-2
        app-panel
          app-header Plan
          .p-4.border-b-1.border-grey-30(v-for="feature in company.subscription.features")
            .grid.grid-cols-4
              .col-span-2
                app-header(size="h3")
                  |{{ feature.name }}
                  template(slot="subheader") {{ feature.description }}
              .col-span-1 {{ feature.value }}
              .col-span-1
      //- .col-span-3
      //-   app-panel
      //-     app-header Transactions
      //-       template(slot="subheader") All stripe transactions for this subscription
</template>
<script>
import SubscriptionQuery from "@/graphql/queries/Subscription.gql";
import ActivateSubscriptionMutation from "@/graphql/mutations/companies/ActivateSubscriptionMutation.gql";
import EditSubscriptionPlanMutation from "@/graphql/mutations/companies/EditSubscriptionPlanMutation.gql";
import CancelSubscriptionMutation from "@/graphql/mutations/companies/CancelSubscriptionMutation.gql";
import ExtendTrialMutation from "@/graphql/mutations/companies/ExtendTrialMutation.gql";
import RestartTrialMutation from "@/graphql/mutations/companies/RestartTrialMutation.gql";
import ExpireTrialMutation from "@/graphql/mutations/companies/ExpireTrialMutation.gql";
import PlansQuery from "@/graphql/queries/Plans.gql";
import { formatDate } from "@/utils/dateUtils.js";

export default {
  data() {
    return {
      plans: [],
    };
  },
  apollo: {
    company: {
      query: SubscriptionQuery,
      variables() {
        return { id: this.$route.params.id };
      },
    },
    plans: {
      query: PlansQuery,
      update(data) {
        return data.plans.edges.map(edge => edge.node);
      },
    },
  },
  computed: {
    availablePlans() {
      const vm = this;
      if(this.company.subscription.status === "Trial" || this.company.subscription.status === "Expired" || this.company.subscription.status === "Cancelled")
        return this.plans
      else
        return vm.plans.filter(
          plan => plan.name != vm.company.subscription.plan.name
        );
    },
    isPayingStatus() {
      return this.company.subscription.isStripeSubscription && this.company.subscription.status === "Active";
    },
    detailsPanel() {
      let fields = [
        { label: "Status", value: this.company.subscription.status, options: this.statusOptions },
        { label: "Start Date", value: formatDate(this.company.subscription.startDate), options: this.startDateOptions },
        { label: "End Date", value: formatDate(this.company.subscription.endDate), options: this.endDateOptions },
        { label: "Plan", value: this.company.subscription.plan.name, options: this.planOptions }
      ]
      if(this.company.subscription.isStripeSubscription && this.company.subscription.status === "Active")
        fields.push(
          { label: "Billing Frequency", value: this.company.subscription.billingFrequency },
          { label: "Payment Method", value: "Stripe" }
        )
      return fields
    },
    statusOptions() {
      const vm = this;
      if(this.company.subscription.status === "Active") {
        return [{ label: "Cancel subscription" }];
      }
      else if(this.company.subscription.status === "Trial") {
        return vm.availablePlans.map(plan => (
          { label: `Turn into active ${plan.name} subscription`, action: "activateSubscription", id: plan.id }
          ))
      }
      else if(this.company.subscription.status === "Cancelled") {
        let availablePlans = vm.availablePlans.map(plan => (
          { label:`Turn into active ${plan.name} subscription`, action: "activateSubscription", id: plan.id }
          ))
          availablePlans.push({ label: "Restart trial"});
        return availablePlans;
      }
      else {
        return null
      }
    },
    startDateOptions() {
      if(this.company.subscription.status === "Trial" || this.company.subscription.status === "Expired"){
        return [{ label: "Restart trial" }];
      }
      else {
        return null
      }
    },
    endDateOptions() {
      if(this.company.subscription.status === "Trial") {
        return [
          { label: "Extend trial by 1 day", action: "extend", duration: 1 },
          { label: "Extend trial by 5 days", action: "extend", duration: 5 },
          { label: "Extend trial by 1 week", action: "extend", duration: 7 },
          { label: "Extend trial by 2 weeks", action: "extend", duration: 14 },
          { label: "Extend trial by 3 weeks", action: "extend", duration: 21 },
          { label: "Extend trial by 4 weeks", action: "extend", duration: 28 },
          { label: "Expire trial" }
          ];
      }
      else if(this.company.subscription.status === "Active") {
        return [
          { label: "Extend subscription by 1 day", action: "extend", duration: 1 },
          { label: "Extend subscription by 5 days", action: "extend", duration: 5 },
          { label: "Extend subscription by 1 week", action: "extend", duration: 7 },
          { label: "Extend subscription by 1 month", action: "extend", duration: 30 },
          { label: "Extend subscription by 3 months", action: "extend", duration: 90 },
          { label: "Extend subscription by 1 year", action: "extend", duration: 365 },
          ];
      }
      else {
        return null
      }
    },
    planOptions() {
      return this.availablePlans.map(plan => (
        { label: plan.name, action: "changePlan", id: plan.id }
      )
      )
    },
  },
  methods: {
    changePlan(plan) {
      const vm = this;
      return this.$apollo.mutate({
        mutation: EditSubscriptionPlanMutation,
        variables: {
          input: {
            plan: {
              id: plan.id,
            },
            company: {
              id: this.company.id,
            },
          },
        },
        update(store, { data: { editSubscriptionPlan } }) {
          vm.updateSubscription(store, editSubscriptionPlan);
        }
      });
    },
    activateSubscription(plan) {
      const vm = this;
      return this.$apollo.mutate({
        mutation: ActivateSubscriptionMutation,
        variables: {
          input: {
            plan: {
              id: plan.id,
            },
            company: {
              id: this.company.id,
            },
          },
        },
        update(store, { data: { activateSubscription } }) {
          vm.updateSubscription(store, activateSubscription);
        }
      });
    },
    cancelSubscription() {
      const vm = this;
      return this.$apollo.mutate({
        mutation: CancelSubscriptionMutation,
        variables: {
          input: {
            company: {
              id: this.company.id,
            },
          },
        },
        update(store, { data: { cancelSubscription } }) {
          vm.updateSubscription(store, cancelSubscription);
        }
      });
    },
    extendTrial(duration) {
      const vm = this;
      return this.$apollo.mutate({
        mutation: ExtendTrialMutation,
        variables: {
          input: {
            company: {
              id: this.company.id,
            },
            duration,
          },
        },
        update(store, { data: { extendTrial } }) {
          vm.updateSubscription(store, extendTrial);
        }
      });
    },
    restartTrial() {
      const vm = this;
      return this.$apollo.mutate({
        mutation: RestartTrialMutation,
        variables: {
          input: {
            company: {
              id: this.company.id,
            },
          },
        },
        update(store, { data: { restartTrial } }) {
          vm.updateSubscription(store, restartTrial);
        }
      });
    },
    expireTrial() {
      const vm = this;
      return this.$apollo.mutate({
        mutation: ExpireTrialMutation,
        variables: {
          input: {
            company: {
              id: this.company.id,
            },
          },
        },
        update(store, { data: { expireTrial } }) {
          vm.updateSubscription(store, expireTrial);
        }
      });
    },
    editActionClicked(item){
      if (item.label === "Cancel subscription") {
        this.cancelSubscription();
      } else if (item.label === "Expire trial") {
        this.expireTrial();
      } else if (item.action === "extend") {
        this.extendTrial(item.duration);
      } else if (item.label === "Restart trial") {
        this.restartTrial();
      } else if (item.action === "changePlan") {
        this.company.subscription.plan.id = item.id
        this.changePlan(this.company.subscription.plan)
      } else if (item.action === "activateSubscription") {
        this.company.subscription.plan.id = item.id
        this.activateSubscription(this.company.subscription.plan)
      }
    },
    updateSubscription(store, company) {
      const variables = {
        id: this.company.id
      };
      const data = store.readQuery({
        query: SubscriptionQuery,
        variables
      })
      data.company.subscription = Object.assign(data.company.subscription, company.subscription);
      store.writeQuery({
        query: SubscriptionQuery,
        variables,
        data
      })
    },
  },
};
</script>
<style lang="postcss" scoped>
.p-4.border-b-1.border-grey-30 {
  padding: 0px;
}
</style>
